<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap
    >
      <p>In Progress </p>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Payouts"
}
</script>
